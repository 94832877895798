export const BgColor = [
  "rgba(255, 99, 132, 0.2)",
  "rgba(54, 162, 235, 0.2)",
  "rgba(255, 206, 86, 0.2)",
  "rgba(200, 206, 86, 0.2)",
  "rgba(255, 150, 86, 0.2)",
];
export const BorderColor = [
  "rgba(255, 99, 132, 1)",
  "rgba(54, 162, 235, 1)",
  "rgba(255, 206, 86, 1)",
  "rgba(200, 206, 86, 1)",
  "rgba(255, 150, 86, 1)",
];
