import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAa6PPhOTRmvK0dLxzE6fqkVUlP5QeRBYc",
  authDomain: "reviws-9815b.firebaseapp.com",
  projectId: "reviws-9815b",
  storageBucket: "reviws-9815b.appspot.com",
  messagingSenderId: "454191250403",
  appId: "1:454191250403:web:5aaea854c7cfdac80d7ed6",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app);
